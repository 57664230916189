import { TEntryRawLikeNestedFieldSet } from '@/specific/orders_helpers/NDS/orderShowNDS/declarations'
import { TAX_RATES, TAX_RATES_TEXTS } from '@/vue_apps/Taxes/const/const'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'

const drawNDS = (tableContainer, entry: TEntryRawLikeNestedFieldSet) => {
  const rowContainer = tableContainer.querySelector(`tr[data-id="${entry.id}"]`)
  if (!rowContainer) { return }

  const ndsTitleContainer = rowContainer.querySelector('.f-order-entry-tax-scheme') as HTMLElement
  const ndsSumContainer = rowContainer.querySelector('.f-order-entry-tax-scheme-sum') as HTMLElement

  if (!ndsTitleContainer) { return }
  ndsTitleContainer.innerText = entry._ndsTitle

  if (!ndsSumContainer) { return }
  ndsSumContainer.innerText = entry.tax_scheme === TAX_RATES.NDS_NONE || entry._ndsTitle === t('ndsnone')
    ? TAX_RATES_TEXTS[TAX_RATES.NDS_NONE]
    : vueFilters.price(entry._ndsValue)
}

export const drawNDSForTopLevelEntries =
  (topLevelEntries: TEntryRawLikeNestedFieldSet[]) => {
    const tableContainer = document.querySelector('#order_entries_area_tbody')
    ;(topLevelEntries || []).forEach((topLevelEntry) => {
      drawNDS(tableContainer, topLevelEntry)
      topLevelEntry._members.forEach((member) => drawNDS(tableContainer, member))
    })
  }
