import { TEntryRawLikeNestedFieldSet } from '@/specific/orders_helpers/NDS/orderShowNDS/declarations'
import { GLOBAL_ENTRY_TYPES_KINDS } from '@/_global_scripts/GLOBAL_CONSTS'
import { getNDSForEntry } from '@/forms/components/order/NDS/getNDSForEntry'
import { getNDSForComplex } from '@/forms/components/order/NDS/getNDSForComplex'

export const prepareTopLevelEntries =
  (topLevelEntries: TEntryRawLikeNestedFieldSet[] = [], members: TEntryRawLikeNestedFieldSet[] = []) => {
    ;(topLevelEntries || []).forEach((topLevelEntry) => {
      topLevelEntry.get = (key) => topLevelEntry[key]
      topLevelEntry._members = (members || []).filter((member) => member.complex_id === topLevelEntry.id)
      topLevelEntry.getMembers = () => topLevelEntry._members

      const { schemeTitle } = topLevelEntry.kind !== GLOBAL_ENTRY_TYPES_KINDS.COMPLEX
        ? getNDSForEntry(topLevelEntry)
        : getNDSForComplex(topLevelEntry)

      topLevelEntry._ndsTitle = schemeTitle
    })
  }
