export enum TAX_RATES {
  NDS_NONE = 'ndsnone',
  NDS_0 = 'nds0',
  NDS_5 = 'nds5',
  NDS_7 = 'nds7',
  NDS_10 = 'nds10',
  NDS_12 = 'nds12',
  NDS_18 = 'nds18',
  NDS_20 = 'nds20',
  NDS_25 = 'nds25',
  NDS_10_TO_110 = 'nds10to110',
  NDS_18_TO_118 = 'nds18to118',
  NDS_20_TO_120 = 'nds20to120',
}

export const UNSPECIFIED_TAX_RATE_TEXT = t('enums.unspecified.it')

export const TAX_RATES_TEXTS = {
  [TAX_RATES.NDS_NONE]: t('ndsnone'),
  [TAX_RATES.NDS_0]: t('nds0'),
  [TAX_RATES.NDS_5]: t('nds5'),
  [TAX_RATES.NDS_7]: t('nds7'),
  [TAX_RATES.NDS_10]: t('nds10'),
  [TAX_RATES.NDS_12]: t('nds12'),
  [TAX_RATES.NDS_18]: t('nds18'),
  [TAX_RATES.NDS_20]: t('nds20'),
  [TAX_RATES.NDS_25]: t('nds25'),
  [TAX_RATES.NDS_10_TO_110]: t('nds10to110'),
  [TAX_RATES.NDS_18_TO_118]: t('nds18to118'),
  [TAX_RATES.NDS_20_TO_120]: t('nds20to120'),
} as const

export const TAX_RATES_SENDABLE_TO_DRIVER = [
  TAX_RATES.NDS_NONE,
  TAX_RATES.NDS_0,
  TAX_RATES.NDS_10,
  TAX_RATES.NDS_20,
  TAX_RATES.NDS_10_TO_110,
  TAX_RATES.NDS_20_TO_120,
]

export enum SYNC_ENTRIES_TAX_RATE_ANSWERS_IDS {
  NO = 1,
  YES_WITHOUT_ORDER = 2,
  YES = 3,
}

export const SYNC_ENTRIES_TAX_RATE_ANSWERS = [
  {
    id: SYNC_ENTRIES_TAX_RATE_ANSWERS_IDS.NO,
    title: t('sync_entries_tax_rate_answers.no_need'),
  },
  {
    id: SYNC_ENTRIES_TAX_RATE_ANSWERS_IDS.YES_WITHOUT_ORDER,
    title: t('sync_entries_tax_rate_answers.yes_without_order'),
  },
  {
    id: SYNC_ENTRIES_TAX_RATE_ANSWERS_IDS.YES,
    title: t('sync_entries_tax_rate_answers.yes_all'),
  },
] as const
