import omitBy from 'lodash/omitBy'
import { MODAL_LAYERS } from '@/modules/utils/modals'
import { cloneDeep, isUndefined } from 'lodash'
import { createSelectClinicModal } from '@/vue_components/users/SelectClinic'
import { GLOBAL_DEBOUNCE_DEFAULT } from '@/_global_scripts/GLOBAL_CONSTS'
import { orderStopSpin } from '@/specific/orders_helpers/orderSpin'
import { TAX_RATES, TAX_RATES_TEXTS } from '@/vue_apps/Taxes/const/const'
import { drawNDS } from '@/forms/components/order/NDS/drawNDS'
import { addDueDateToComment } from '@/forms/components/order/DueDate/addDueDateToComment'

export const getDefaultGlobalDiscount = (payerDefault = null) => {
  const payer = payerDefault || gon.specific.payer_client || gon.specific.payer_company || {}
  const discountType = 'percent'

  const accumulatedDiscount = parseFloat(payer.accumulated_discount) || 0
  const payerDiscount = parseFloat(payer.discount) || 0
  const discountValue = Math.max(accumulatedDiscount, payerDiscount)

  return { discountType, discountValue }
}

FormFactory.orderEntryList = function (params) {
  const ENTRY_NOT_READY = 1

  const msgPrefix = 'page.form.' + params.model + '.orderEntryList.'

  const container = params.orderEntryList.container
  let globalEntriesAttributes = {}
  const entriesTemplate = $('._template_entries')
  const round = Utils.moneyRound
  const $roundPricesCheckbox = $('#round_order_prices')
  const shouldRoundPrices = () => $roundPricesCheckbox.is(':checked')
  const globalReferralStore = {} // простите меня, пожалуйста

  if (gon.specific.defaultStore) {
    globalEntriesAttributes.store_id = gon.specific.defaultStore.id
  }

  /**
   * @typedef {{store_id: string, assistant_id: string, referral_id: string, user_id: string}} BulkEntryAttributes
   */

  /**
   * @property {BulkEntryAttributes} attributes
   */
  class DefaultRowAttributes {
    constructor () {
      this.attributes = {
        store_id: '',
        user_id: '',
        assistant_id: '',
        referral_id: '',
      }

      if (!gon.specific.useAssistants) {
        delete this.attributes.assistant_id
      }
      /**
       * @type {HTMLSelectElement[]}
       */
      this.$bulkSelects = $('.mass-select select').toArray()
    }

    init () {
      this.updateBulkAttributes()
    }

    /**
     * Get values from mass selects, they are not available in `gon.specific.order`
     */
    updateBulkAttributes () {
      this.$bulkSelects.forEach((select) => {
        this.attributes[select.name] = select.value
      })
    }

    /**
     * @param {EntryKind} rowType
     * @return {BulkEntryAttributes}
     */
    getFor (rowType) {
      let attrs

      switch (rowType) {
        case 'good': {
          attrs = ['store_id']
          break
        }
        default: {
          attrs = Object.keys(this.attributes)
        }
      }

      return attrs.reduce((map, attr) => {
        map[attr] = this.attributes[attr]

        return map
      }, {})
    }
  }

  const defaultRowAttributes = new DefaultRowAttributes()

  /**
   * Сброс глобальных значений для скидки в globalEntriesAttributes
   */
  const resetDiscountInGlobalEntriesAttributes = () => {
    const { discountType, discountValue } = getDefaultGlobalDiscount()

    globalEntriesAttributes = {
      ...globalEntriesAttributes,
      discount_type: discountType,
      discount_value: Utils.toMoney(discountValue),
    }
  }

  //
  // core funcs
  //

  const init = function () {
    defaultRowAttributes.init()
    const currentClinicId = gon.application.current_clinic.id
    const fromOtherClinic = []

    const entryRows = container.find('.entry_row')
    entryRows.each((i, e) => {
      const row = $(e).nestedFieldSet()

      if (gon.specific.order_locked) {
        row.lockFromBillingChanges()
      }

      const clinicId = row.get('clinic_id', 'int')
      if (clinicId !== currentClinicId) {
        fromOtherClinic.push(row)
      }

      row.init()
      row.hideContent()
      row.setEmptyRecursive(defaultRowAttributes)

      if (shouldRoundPrices()) {
        row.roundPrice()
      }

      if (row.isComplex()) {
        row.html().addClass('f-order-entry-complex')
        row.showContent()
      }
    })

    if (fromOtherClinic.length) {
      const buttons = {
        apply: {
          label: t('delete_from_order'),
          className: 'btn-danger',
          callback () {
            fromOtherClinic.forEach(removeRow)
          },
        },
        change: {
          label: t('change_clinic'),
          className: 'btn-info',
          callback () {
            createSelectClinicModal()
          },
        },
        confirm: {
          label: t('keep_in_order'),
          className: 'btn-success',
          callback () {},
        },
      }

      const clinics = fromOtherClinic.reduce((clinics, row) => {
        const clinicId = row.get('clinic_id', 'int')
        const clinicTitle = gon.specific.clinics[clinicId].title

        if (!clinics[clinicTitle]) {
          clinics[clinicTitle] = []
        }

        clinics[clinicTitle].push(row.get('title'))

        return clinics
      }, {})
      const list = Object.keys(clinics).map((clinic) => {
        const list = clinics[clinic].map((entryTitle) => `<li>${entryTitle}</li>`).join('')

        return `<li><span class="warning bold">${clinic}:</span> <ul>${list}</ul></li>`
      }).join('')

      const dialog = bootbox.dialog({
        message: `
          <div class="w-100 flex gap-indent">
            <div class="">
              <i class="fad fa-exclamation-triangle warning font-size-24"></i>
            </div>
            
            <div class="flex-grow-1">
              <div class="mb-indent-mid">${T.services_from_other_clinic}</div>
              <ul class="mb-indent-validation">${list}</ul>
              <div class="bold">${T.should_keep_in_order}</div>
            </div>  
          </div>
        `,
        closeButton: false,
        backdrop: true,
        buttons,
        title: t('services_from_other_clinic_found'),
      })
      dialog.css('z-index', MODAL_LAYERS.DEFAULT)
      dialog.find('[data-bb-handler="apply"]').attr('title', t('remove_only_from_order'))
      dialog.find('[data-bb-handler="confirm"]').attr('title', t('move_entry_to_current_clinic'))
      Utils.updateTooltipsInContainer(dialog)
    }

    triggerListChanged()
  }

  let updateIndexesTimeout
  const updateIndexes = function () {
    if (updateIndexesTimeout) clearTimeout(updateIndexesTimeout)

    // massive complex addition calls updateIndex too many times and freezes ui
    updateIndexesTimeout = setTimeout(function () {
      $(container[0]).find('.entry_row').get().forEach(function (e, i) {
        const row = $(e).nestedFieldSet()
        row.setIndex(i)
        row.init()
      })
    }, 10)
  }

  const currentOrderDate = () => $('#order_date').val()

  const buildItem = function (proto) {
    let rezDiscount
    if (globalEntriesAttributes.discount_disabled || proto.discount_disabled) {
      rezDiscount = 0
    } else {
      rezDiscount = globalEntriesAttributes.discount_type === 'currency' ? 0 : globalEntriesAttributes.discount_value
    }

    const assistantId = gon.specific.assistantLast?.id ||
      globalEntriesAttributes.assistant_id ||
      gon.specific.defaultAssistant?.id ||
      ''

    const userId = gon.specific.userLast?.id ||
      globalEntriesAttributes.user_id ||
      gon.specific.defaultUser?.id ||
      ''

    const storeId = gon.specific.storeLast?.id ||
      globalEntriesAttributes.store_id ||
      gon.specific.defaultStore?.id ||
      ''

    const referralId = gon.specific.referralLast?.id ||
      globalEntriesAttributes.referral_id ||
      gon.specific.defaultReferral?.id ||
      ''

    return {
      amount: proto.amount,
      assistant_id: assistantId,
      client_id: globalEntriesAttributes.client_id,
      clinic_id: globalEntriesAttributes.clinic_id,
      date: globalEntriesAttributes.date || currentOrderDate(),
      discount_disabled: proto.discount_disabled,
      discount_value: rezDiscount || 0,
      discount_type: globalEntriesAttributes.discount_type,
      entry_type_id: proto.id,
      final_price: proto.price,
      kind: proto.kind,
      number: proto.number,
      price: proto.price,
      state: ENTRY_NOT_READY,
      store_id: storeId,
      title: proto.title,
      user_id: userId,
      referral_id: referralId,
      account: globalEntriesAttributes.account,
      tax_scheme: proto.tax_scheme || gon.application.current_clinic.tax_scheme,
    }
  }

  let triggerListChangedDelay = null
  var triggerListChanged = function () {
    if (triggerListChangedDelay) clearTimeout(triggerListChangedDelay)
    triggerListChangedDelay = setTimeout(function () {
      const result = {
        sum: 0,
        final_sum: 0,
        discountable_sum: 0,
        discount_sum: 0,
        nds: 0,
      }

      const taxSchemes = new Set()
      container.find('.f-order-entry').each(function (i, e) {
        const row = $(e).nestedFieldSet()

        if (row.isComplex() || row.isDeleted()) return

        if (!row.get('discount_disabled', 'bool')) {
          result.discountable_sum += row.get('sum', 'float')
        }

        result.sum += row.get('sum', 'float')
        result.final_sum += row.get('final_sum', 'float')
        result.discount_sum += row.get('discount_sum', 'float')

        result.nds += (row._ndsValue || 0)

        const taxScheme = row.get('tax_scheme')
        taxScheme && taxSchemes.add(taxScheme)
      })

      result.sum = round(result.sum)
      result.final_sum = round(result.final_sum)
      result.discount_sum = round(result.discount_sum)
      result.nds = round(result.nds)

      PubSub.emit('page.form.' + params.model + '.updateComponents')
      PubSub.emit(msgPrefix + 'listChanged', result)

      if (taxSchemes.has(TAX_RATES.NDS_NONE) && taxSchemes.size === 1) {
        setTimeout(() => {
          document.querySelector('#order_nds_sum_value').innerText = TAX_RATES_TEXTS[TAX_RATES.NDS_NONE]
        })
      }
    }, 10)
  }

  // remove passed attributes & undefined
  const cleanAttributes = function (attrs, ...attrsToOmit) {
    return omitBy(attrs, (value, key) => attrsToOmit.includes(key) || value === undefined)
  }

  const postAdd = () => {
    updateIndexes()
    triggerListChanged()
  }

  /**
   * @param {boolean} show
   * @return {jQuery}
   */
  const showEntriesLoader = (show) => container.parent().loadSpin(show ? 'start' : 'stop')

  const addEntry = function (entryType) {
    const entryProto = $.extend({}, entryType, { amount: 1 })
    const entryAttributes = omitBy(buildItem(entryProto), _.isUndefined)
    const entryHtml = entriesTemplate.railsTemplate('entries').html

    // Записываем прайс в hidden поля для свежедобавленной энтри
    // чтобы потом нормально работали скидки #1
    const entryPrice = entryType.price
    entryHtml.first().find('td.hidden .order_entries_price input').val(entryPrice)

    const entryRow = entryHtml.first().nestedFieldSet()
    const clientId = gon.specific.client_id

    $.ajax({
      url: Routes.duplicate_validation_entries_path(),
      method: 'POST',
      data: {
        entry_type_id: entryType.id,
        client_id: clientId,
      },
      success: (entry) => checkDuplicate(entry),
      error: (err) => console.error(err),
      complete () {
        /**
         * this loader started in order preset, on
         * `fancyEntryTypes.itemLoading.start` event
         */
        showEntriesLoader(false)
      },
    })

    addAttributeTitleToWorkToTooltips(entryHtml, '.entry-title .tooltip-bottom', entryType.title)

    function checkDuplicate (entry) {
      if (!entry) {
        addSelectedEntry()
      } else {
        bootbox.confirmYN(t('entry_duplicated'), function (result) {
          if (result) {
            addSelectedEntry()
          }
        })
      }
    }

    function addSelectedEntry () {
      entryRow.set(entryAttributes)
      entryRow.setEmpty(defaultRowAttributes.getFor(entryRow.getKindString()))

      if (shouldRoundPrices()) {
        entryRow.roundPrice()
      }

      container.append(entryHtml)

      if (entryRow.isComplex()) {
        // in laboratories we use members as linked entries, head entry have kind = 1 (analysis)
        // gotta make sure we don't try to save it as complex in order,
        // because it will not work that way
        entryProto.entry_type_members.forEach(function (member) {
          addMember(member.member, {
            complexRow: entryRow,
            amount: member.amount,
            skipInit: true,
          })
        })
      }

      addDueDateToComment(entryRow, entryType)

      entryProto.entry_type_consumables.forEach(function (consumable) {
        addConsumable(consumable.consumable, {
          amount: consumable.amount,
          entryRow,
        })
      })

      entryRow.init()
      entryRow.hideContent()

      if (entryRow.isComplex()) {
        entryRow.html().addClass('f-order-entry-complex')
        entryRow.showContent()
      }

      entryRow.recalculate()
      drawNDS(entryRow)
      postAdd()

      PubSub.emit('referralSelect', { ...globalReferralStore, setSelected: true, _source: 'addSelectedEntry' })
    }
  }

  var addMember = function (entryType, params) {
    const complexRow = params.complexRow

    const memberHtml = complexRow
      .getMemberTemplate()
      .railsTemplate('members').html

    // Записываем прайс в hidden поля для свежедобавленной энтри
    // чтобы потом нормально работали скидки #2
    const memberPrice = entryType.price
    memberHtml.first().find('td.hidden .f-order-entry-price').val(memberPrice)

    addAttributeTitleToWorkToTooltips(memberHtml, '.member_title .tooltip-bottom', entryType.title)

    const memberRow = memberHtml.first().nestedFieldSet()
    const memberAmount = params.amount || 1
    let memberAttributes = buildItem($.extend({}, entryType, {
      amount: memberAmount,
    }))

    memberAttributes.discount_value = memberAttributes.discount_value ? memberAttributes.discount_value : 0
    memberAttributes = cleanAttributes(memberAttributes, 'order_id')

    if (complexRow.get('discount_disabled', 'bool')) {
      memberAttributes.discount_disabled = true
      memberAttributes.discount_value = 0
    }

    memberRow.set(memberAttributes)
    memberRow.setEmpty(defaultRowAttributes.getFor(memberRow.getKindString()))

    addDueDateToComment(memberRow, entryType)

    if (shouldRoundPrices()) {
      memberRow.roundPrice()
    }

    complexRow.appendMemberHtml(memberHtml)

    entryType.entry_type_consumables.forEach(function (consumable) {
      addConsumable(consumable.consumable, {
        amount: consumable.amount * memberAmount,
        entryRow: memberRow,
      })
    })

    memberRow.recalculate()
    drawNDS(memberRow)

    if (!params.skipInit) {
      memberRow.hideContent()
      complexRow.init()
      postAdd()
    }

    showEntriesLoader(false)
  }

  var addConsumable = function (entryType, params) {
    const entryRow = params.entryRow

    const consumableHtml = entryRow
      .getConsumableTemplate()
      .railsTemplate('consumables').html

    addAttributeTitleToWorkToTooltips(consumableHtml, '.consumable_title .tooltip-bottom', entryType.title)
    const consumableRow = consumableHtml.first().nestedFieldSet()

    let consumableAttributes = buildItem($.extend({}, entryType, {
      amount: params.amount || 1,
    }))

    $.extend(consumableAttributes, {
      price: 0,
      final_price: 0,
      sum: 0,
      final_sum: 0,
      discount_value: 0,
      discount_sum: 0,
      consumable: true,
    })

    consumableAttributes = cleanAttributes(consumableAttributes,
      'order_id', 'user_id', 'assistant_id', 'referral_id'
    )

    consumableRow.set(consumableAttributes)
    consumableRow.setEmpty(defaultRowAttributes.getFor(consumableRow.getKindString()))
    entryRow.appendConsumableHtml(consumableHtml)

    if (!params.skipInit) {
      entryRow.redraw()
      consumableRow.redraw()
      postAdd()
    }

    showEntriesLoader(false)
  }

  var addAttributeTitleToWorkToTooltips = function (entryHtml, selector, title) {
    entryHtml.find(selector).attr('title', title)
  }

  /**
   * Checks if all fields that should be changed are not set
   *
   * @param {{change: string}} opts
   * @return {boolean}
   */
  const changingFieldsAreEmpty = function (opts) {
    const attributes = {
      performer: 'user_id',
      assistant: 'assistant_id',
      referral: 'referral_id',
      date: 'date',
    }

    return container.find('.f-order-entry').toArray().every(function (e) {
      const row = $(e).nestedFieldSet()
      const currentAttrs = row.get()

      return !currentAttrs[attributes[opts.change]]
    })
  }

  const setAttributes = function (opts, entryElement = null) {
    let discountDifference = globalEntriesAttributes.difference || 0
    delete globalEntriesAttributes.difference

    let discountValue = parseFloat(globalEntriesAttributes.discount_value)
    const $rows = entryElement ? $(entryElement) : container.find('.f-order-entry')
    const $consumableRows = entryElement ? $(entryElement) : container.find('.f-order-nested-consumable')

    if (opts.multiple && Object.hasOwn(opts, 'newStoreId')) {
      globalEntriesAttributes.store_id = opts.newStoreId || ''
    }

    $rows.each(function (i, e) {
      let newAttrs = Object.assign({}, globalEntriesAttributes)
      const row = $(e).nestedFieldSet()

      const isDeleted = row.isDeleted()

      const discountDisabled = row.get('discount_disabled', 'bool') || isDeleted

      if (discountDifference !== 0 && !discountDisabled && !row.isComplex()) {
        if ((newAttrs.discount_value + discountDifference) > 0) {
          newAttrs.discount_value = newAttrs.discount_value + discountDifference
          discountDifference = 0
        } else {
          const correctedDifference = Math.round(discountDifference / 2)
          newAttrs.discount_value = newAttrs.discount_value + correctedDifference
          discountDifference = discountDifference - correctedDifference
        }
      }

      if (!discountDisabled && newAttrs.discount_value !== undefined) {
        row.resetLastDiscount()
      }

      if (row.isConsumable()) {
        newAttrs = _.omit(newAttrs, 'user_id', 'assistant_id', 'referral_id')
      }
      const currentAttrs = row.get()

      // Если цена === 0 и мы пытаемся поставить скидку в валюте, то это получится только если скидка === 0,
      // иначе сумма будет отрицательной. Если скидка ставится в процентах, то допускается любая.
      const isCurrencyDiscount = newAttrs.discount_type === 'currency' ||
        (!newAttrs.discount_type && row.get('discount_type') === 'currency')
      const isSettingNegativePrice = row.get('price', 'float') === 0 &&
        newAttrs.discount_value !== 0 &&
        isCurrencyDiscount

      if (discountDisabled || isSettingNegativePrice) delete newAttrs.discount_value
      if (currentAttrs.date && !(opts.change === 'date')) delete newAttrs.date
      if (currentAttrs.user_id && !(opts.change === 'performer')) delete newAttrs.user_id
      if (currentAttrs.assistant_id && !(opts.change === 'assistant')) delete newAttrs.assistant_id
      if (currentAttrs.referral_id && !(opts.change === 'referral')) delete newAttrs.referral_id
      if (!currentAttrs.state && !(opts.change === 'state')) newAttrs.state = ENTRY_NOT_READY

      // if we are not forcing attributes, remove all discount related fields from attrs
      if (!opts.force) {
        delete newAttrs.discount_value
        delete newAttrs.discount_type
      }

      /**
       * See app/assets/javascripts/forms/form_factory/components/treatment_plan/list.js
       * in `setAttributes`
       */
      if (newAttrs.discount_type === 'currency') {
        if (discountValue > 0) {
          if (
            !discountDisabled &&
            !row.isConsumable() &&
            !row.isComplex()
          ) {
            const discountableSum = row.get('sum', 'float')
            const applied = Math.min(discountValue, discountableSum)
            discountValue = round(discountValue - applied)
            newAttrs.discount_value = applied
          }
        } else {
          newAttrs.discount_value = 0
        }
      }

      if (newAttrs.discount_value) {
        row.field('discount_value').inputmask('remove')
      }
      row.set(newAttrs)
      if (newAttrs.discount_value) {
        row.field('discount_value').trigger('change')
      }
    })

    if (opts.multiple && Object.hasOwn(opts, 'newStoreId')) {
      $consumableRows.each((index, element) => {
        const consumableRow = $(element).nestedFieldSet()
        consumableRow.set('store_id', globalEntriesAttributes.store_id)
      })
    }

    container.find('.entry_row').each(function (i, e) {
      const row = $(e).nestedFieldSet()
      row.recalculate()
      drawNDS(row)
    })

    if (opts.updateLabels) {
      opts.updateLabels()
    }

    triggerListChanged()
  }

  const openHiddenErrors = function (e, data) {
    const entriesErrors = data.errors.entries_attributes
    if (!entriesErrors) return

    const entries = container.find('.entry_row').toArray().map(function (e) {
      return $(e).nestedFieldSet()
    })

    Object.keys(entriesErrors).forEach(function (entryIndex) {
      const entry = entries[parseInt(entryIndex)]
      if (!entry) return

      const entryErrors = entriesErrors[entryIndex]
      const membersErrors = entryErrors.members_attributes

      if (membersErrors) {
        Object.keys(membersErrors).forEach(function (memberIndex) {
          const member = entry.getDataArray('members')[parseInt(memberIndex)]
          const memberErrors = membersErrors[memberIndex]
          const consumableErrors = memberErrors.consumables_attributes
          if (member && consumableErrors) member.showContent()
        })
      } else {
        const consumableErrors = entryErrors.consumables_attributes
        if (consumableErrors) entry.showContent()
      }
    })
  }

  //
  // PubSub subscriptions
  //

  PubSub.on(msgPrefix + 'recalculateTotals', () => {
    triggerListChanged()
  })

  PubSub.on(`${msgPrefix}askAddEntries`, (target, { entryTypes, onComplete }) => {
    const entryRows = entryTypes.map((entryType) => {
      const entryAttributes = omitBy(buildItem(entryType), isUndefined)
      const entryHtml = entriesTemplate.railsTemplate('entries').html
      const entryRow = entryHtml.first().nestedFieldSet()

      // Записываем прайс в hidden поля для свежедобавленной энтри
      // чтобы потом нормально работали скидки #1
      const entryPrice = entryType.price
      entryHtml.first().find('td.hidden .order_entries_price input').val(entryPrice)

      // Тултип
      addAttributeTitleToWorkToTooltips(entryHtml, '.entry-title .tooltip-bottom', entryType.title)

      entryRow.set(entryAttributes)
      entryRow.setEmpty(defaultRowAttributes.getFor(entryRow.getKindString()))

      if (shouldRoundPrices()) { entryRow.roundPrice() }

      // todo: попробовать вынести
      container.append(entryHtml)

      // Отрисовка комплекса
      if (entryRow.isComplex()) {
        (entryType.entry_type_members || []).forEach(function (member) {
          addMember(member.member, {
            complexRow: entryRow,
            amount: member.amount * entryType.amount,
            skipInit: true,
          })
        })
      }

      addDueDateToComment(entryRow, entryType)

      // Отрисовка расходников
      ;(entryType.entry_type_consumables || []).forEach(function (consumable) {
        addConsumable(consumable.consumable, {
          amount: consumable.amount * entryType.amount,
          entryRow,
          skipInit: true,
        })
      })

      return entryRow
    })

    // Отрисовка
    entryRows.forEach((entryRow) => {
      entryRow.init()
      entryRow.hideContent()

      if (entryRow.isComplex()) {
        entryRow.html().addClass('f-order-entry-complex')
        entryRow.showContent()
      }
    })

    // Пересчет
    entryRows.forEach((entryRow) => {
      entryRow.recalculate()
    })

    postAdd()

    PubSub.emit('referralSelect', { ...globalReferralStore, setSelected: true, _source: 'addSelectedEntry' })

    setTimeout(() => {
      if (typeof onComplete === 'function') {
        onComplete()
      }

      orderStopSpin()
    }, GLOBAL_DEBOUNCE_DEFAULT)
  })

  PubSub.on(msgPrefix + 'askAddEntry', function (msg, data) {
    resetDiscountInGlobalEntriesAttributes()

    if (!data.selector) return addEntry(data.item)

    const selectorRow = data.selector.closest('tr')
    const complex = selectorRow.data('complex')
    const entry = selectorRow.data('entry')
    const type = selectorRow.data('type')

    switch (type) {
      case 'members':
        addMember(data.item, {
          amount: parseInt(complex.get('amount')),
          complexRow: complex,
        })
        break
      case 'consumables':
        addConsumable(data.item, {
          amount: parseInt(entry.get('amount')),
          entryRow: entry,
        })
        break
      default:
        addEntry(data.item)
    }

    if (gon.application.use_tips_in_orders) { tipNotify(data.item) }
  })

  PubSub.on(msgPrefix + 'askSetAttributes', function (msg, data) {
    globalEntriesAttributes = $.extend(
      globalEntriesAttributes,
      omitBy($.extend({}, data.attributes), _.isUndefined)
    )
    defaultRowAttributes.updateBulkAttributes()

    if (changingFieldsAreEmpty(data) || !data.change) {
      const attributes = {
        force: false,
        updateLabels: data.updateLabels,
        multiple: data.multiple,
      }

      if (data.change === 'store') {
        attributes.newStoreId = data.attributes?.store_id || ''
      }

      setAttributes(attributes)

      return
    }

    // check if bootbox already showed
    const confirmMsg = t('change_field') + ' ' + t(data.change) + ' ' + t('for_all_positions')
    if ($('.bootbox-body').html()) {
      return
    }

    if (data.change !== 'date') {
      return askUserToConfirm(confirmMsg, data)
    }

    // "Запретить изменение даты услуг при создании счета"
    if (gon.specific.denyPaidOrderAttributes) {
      return
    }

    if (checkIfDateChanged(data.attributes)) {
      askUserToConfirm(t('order_date_warning'), data, () => updateEntryTitleTooltips(data.attributes?.date))
    }
  })

  function updateEntryTitleTooltips (date) {
    $('#order_entries_table').find('.f-order-entry').each(function (i, item) {
      const titleSelector = $(item).find('.order_entries_title input')
      const titleVal = $(titleSelector).val()

      $(titleSelector).prop('title', [titleVal, date].filter(Boolean).join(', '))
    })

    Utils.updateTooltips(document.querySelector('#order_entries_table'))
  }

  function checkIfDateChanged (newAttrs) {
    return container.find('.f-order-entry').toArray().some(function (e) {
      const row = $(e).nestedFieldSet()
      const currentAttrs = row.get()

      return currentAttrs.date !== newAttrs.date.split('.').reverse().join('-')
    })
  }

  function askUserToConfirm (msg, data, yesCallback = () => {}) {
    const confirmationModal = bootbox.confirmYN(msg, function (res) {
      if (res) {
        setAttributes({ force: false, change: data.change, updateLabels: data.updateLabels })
        yesCallback()
      }
    })
    confirmationModal.css('z-index', MODAL_LAYERS.LOW)
  }

  PubSub.on(msgPrefix + 'askForceAttributes', function (msg, data) {
    let entryElement

    Object.keys(data.attributes).forEach((key) => {
      if (key === 'entryElement') {
        entryElement = data.attributes.entryElement

        return
      }
      if (data.attributes[key] !== undefined) globalEntriesAttributes[key] = data.attributes[key]
    })

    setAttributes({ force: true, change: data.change }, entryElement)
    $('.discount-type-sign.selected').trigger('click')
  })

  /** In discounts operations make sure that you maintain correct discount type.
   * Changing payer changes the discount, applied to all entries, but changing
   * discount value there is always in percents, so if discount type on some entries
   * is set to currency, you have a problem. Force correct discount type.
   */
  PubSub.on(msgPrefix + 'askSetNewPayerDiscount', function (_, discountValue) {
    // here we are setting payer's discount only for entries with zero discount
    container.find('.f-order-entry').each((_, e) => {
      const row = $(e).nestedFieldSet()

      // use SYSTEM_DISCOUNTS here when modules are available
      const canSetPersonalDiscount = !row.get('discount_value', 'float')

      if (!row.get('discount_disabled', 'bool') && canSetPersonalDiscount) {
        const discountType = 'percent'
        row.set({
          discount_value: row.formatForMoneyField(discountValue, discountType),
          discount_type: discountType,
        })
      }
    })
  })

  PubSub.on('page.form.' + params.model + '.setNew', init)
  PubSub.on('page.form.' + params.model + '.setEdit', init)
  PubSub.on('page.form.' + params.model + '.submitError', openHiddenErrors)

  //
  // events
  //

  let prevRowState = {}
  const recalculateSelector =
    '.f-order-entry-price, .f-order-member-price, ' +
    '.f-order-entry-amount, .f-order-member-amount, ' +
    '.f-order-entry-discount-value, .f-order-member-discount-value, ' +
    '.f-entry-referral'
  container.on('keyup change mouseup', recalculateSelector, function (e) {
    const row = $(this).closest('tr').nestedFieldSet()

    /**
     * Описание бага:
     * 1) должно быть включено изменение цены в настройках/счета
     * 2) в энтре используется рублевая скидка
     * 3) сам баг: изменяется цена (price) энтри,
     *    значение скидки (discount_value) становится равным предыдущему? значению price
     *
     * Описания костыля:
     * 1) используется три события: keyup(isKeyUpEvent), change(isChangeEvent), mouseup(isMouseUpEvent)
     * 2) сохранение предыдущего стейта энтри на событиях:
     *    mouseup - встали в поле цена (price)
     *    change - когда изменяется значение скидки из модалки скидок
     * 3) если isKeyUpEvent и измененилась цена (isPriceChanged), а также discount_type === 'currency',
     *    то скидка не будет изменена и текущий баг не проявится.

     *  Решение работает, однако, в поле скидки произойдет быстрое появление неправильной скидки и замена на правильную,
     *  считаю, что эта проблема не требует внимания
     */
    // НАЧАЛО КОСТЫЛЯ --------------------------------------------------------------------------------------------------
    const targetClassList = Array.from(e.target.classList)
    const isPriceChanged =
      targetClassList.includes('f-order-entry-price') || targetClassList.includes('f-order-member-price')
    const isMouseUpEvent = e.type === 'mouseup'
    const isKeyUpEvent = e.type === 'keyup'
    const isChangeEvent = e.type === 'change'

    if ((isPriceChanged && isMouseUpEvent) || isChangeEvent) {
      prevRowState = cloneDeep(row.get())
    }

    if (isPriceChanged && isKeyUpEvent) {
      const currentRow = row.get()
      if (currentRow.discount_type !== 'currency') { return }
      row.set('discount_value', prevRowState.discount_value)
    }
    // КОНЕЦ КОСТЫЛЯ ---------------------------------------------------------------------------------------------------

    if (row.getName() === 'members') {
      row.data('complex').recalculate()
    } else {
      row.recalculate()
    }

    triggerListChanged()
  })

  container.on('keyup change mouseup', '.f-order-entry-amount', function () {
    const row = $(this).closest('tr').nestedFieldSet()

    if (row.getName() === 'members') row.data('complex').updateSchema()
    if (row.getName() === 'consumables') row.data('entry').updateSchema()

    row.updateContentItems()
    triggerListChanged()
  })

  container.on('click', '.toggle-content-button', function () {
    const row = $(this).closest('tr').nestedFieldSet()
    if (row.enableConsumableSelector()) {
      PubSub.emit('page.form.' + params.model + '.updateComponents')
    }

    row.toggleContent()
  })

  const removeRow = (row) => {
    const entry = row.data('entry')

    if (entry) {
      entry.getConsumableTemplate().railsTemplate('consumables', 'reduceIndex')
    }

    row.gracefulDestroy()
    updateIndexes()

    if (entry) {
      entry.redraw()
    }

    triggerListChanged()
  }

  container.on('click', '.f-nested-destroy', function (e) {
    e.preventDefault()
    removeRow($(this).closest('tr').nestedFieldSet())
  })

  PubSub.on('updateIndexes', () => { updateIndexes() })

  return {}
}
