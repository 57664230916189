<template>
  <div
    class="m-panel"
    :class="[mPanelClass, {'m-panel-collapsed': collapsed,}]"
  >
    <div
      class="m-panel__head hidden-print"
      :class="[headCssClasses, `m-panel__head_${extractedType}`]"
      @click="collapsable && collapsed && onCollapse()"
    >
      <slot name="head">
        <slot name="icon">
          <i
            v-tooltip="collapsed && title"
            class="m-panel__icon hidden-print"
            :class="correctIcon"
          />
        </slot>

        <span
          v-if="!collapsed"
          class="m-panel__title hidden-print"
        >
          {{ title }}
        </span>

        <div
          v-if="$slots.headExtra || usePrintButton"
          class="m-panel__head_extra flex gap-indent-small hidden-print"
        >
          <slot name="headExtra">
            <m-button
              v-if="usePrintButton"
              button-style="fill"
              :text="t('print')"
              icon="print"
              type="dark"
              size="mini"
              @click="onPrintClick"
            />
          </slot>
        </div>
      </slot>

      <i
        v-if="collapsable"
        v-tooltip="t('roll')"
        class="m-panel__collapse-button fad fa-fw"
        :class="collapseIcon"
        @click.stop="onCollapse"
      />

      <m-icon
        v-if="useCloseButton"
        class="pointer"
        icon="close"
        @click="$emit('close')"
      />
    </div>

    <div
      v-loading="bodyLoading"
      class="m-panel__body"
      :class="bodyCss"
    >
      <slot />
    </div>

    <div
      v-if="hasFooter"
      class="m-panel__footer"
    >
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import { iconsMap } from '@/modules/utils/medods_ui/iconsMap'
import { GLOBAL_DEFAULT_COLORS } from '@/_global_scripts/GLOBAL_CONSTS'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'

export default {
  name: 'MPanel',
  components: { MButton, MIcon },
  props: {
    type: PropsTypes.String('primary'),

    collapsable: Boolean,
    isParentControl: Boolean,
    isCollapsed: Boolean,
    icon: PropsTypes.String('cloud'),
    title: PropsTypes.String(''),

    headCssClasses: PropsTypes.Custom([String, Array]),
    bodyCssClasses: PropsTypes.Custom([String, Array, Object]),
    mPanelClass: PropsTypes.String('flex-grow-1'),

    useCloseButton: Boolean,
    usePrintButton: Boolean,
    useTypeByRoute: { type: Boolean, default: true },

    placement: { type: String, default: 'left' },
    bodyLoading: Boolean,
  },

  data () {
    return {
      localCollapsed: false,
    }
  },

  computed: {
    collapsed: {
      get () { return Utils.ternary(this.isParentControl, this.isCollapsed, this.localCollapsed) },
      set (value) {
        this.isParentControl
          ? this.$updateSync('isCollapsed', value)
          : this.localCollapsed = value
      },
    },

    collapseIcon () {
      return this.placement === 'left'
        ? 'fa-chevron-left'
        : 'fa-chevron-right'
    },

    correctIcon () {
      const icon = iconsMap[this.icon] || `fa-${this.icon}`

      return `fad ${icon}`
    },

    hasFooter () {
      return Boolean(this.$slots.footer)
    },

    extractedType () {
      if (!this.$route || !this.useTypeByRoute) { return this.type }

      if (this.$route.path.endsWith('new')) { return GLOBAL_DEFAULT_COLORS.SUCCESS }
      if (this.$route.path.endsWith('edit')) { return GLOBAL_DEFAULT_COLORS.WARNING }

      return this.type
    },

    bodyCss () {
      const bodyCssClasses = Array.isArray(this.bodyCssClasses)
        ? this.bodyCssClasses
        : [this.bodyCssClasses]

      return [
        {
          'no-footer': !this.hasFooter,
        },
        ...bodyCssClasses,
      ]
    },
  },

  methods: {
    onCollapse () {
      if (!this.collapsable) { return }
      this.collapsed = !this.collapsed
    },

    onPrintClick () {
      window.print()
    },
  },
}
</script>
