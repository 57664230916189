import { ItemBase } from '@/_api/_classes/ItemBase'
import { IClinic } from '@/_declarations/IClinic'
import { MAddressModel } from '@/_baseModels/MAddressModel'
import { IAddress } from '@/_declarations/IAddress'
import { TAX_RATES } from '@/vue_apps/Taxes/const/const'

export class MClinicModel extends ItemBase implements IClinic {
  /** @protected */
  _permit = [
    'accessibleFromClientWidget',
    'directorId',
    'email',
    'fax',
    'frmoDepartmentOid',
    'frmoOid',
    'id',
    'taxRate',
    'inn',
    'kpp',
    'legalName',
    'license',
    'licenseIssueDate',
    'licenseIssueDateEnd',
    'licenseIssuer',
    'logoLegacy',
    'lpuId',
    'messageAddress',
    'messageTitle',
    'netrikaGuid',
    'numberOfMis',
    'ogrn',
    'okpo',
    'onlineRecordingInformation',
    'phone1',
    'phone2',
    'servicesAvailableInClientWidget',
    'showOnlineRecordingInformation',
    'specialistsAvailableInClientWidget',
    'status',
    'timeZone',
    'title',
    'web',
    'feedbackUrl',
  ]

  accessibleFromClientWidget: boolean = false

  addressAttributes: MAddressModel = new MAddressModel()

  directorId: number = null

  email: string = ''

  fax: string = ''

  frmoDepartmentOid: string = ''

  frmoOid: string = ''

  id: number = null

  taxRate = TAX_RATES.NDS_NONE

  taxRateOldValue = TAX_RATES.NDS_NONE

  inn: string = ''

  kpp: string = ''

  legalName: string = ''

  license: string = ''

  licenseIssueDate: string = null

  licenseIssueDateEnd: string = null

  licenseIssuer: string = ''

  logoLegacy: { url: string } = { url: '' }

  lpuId: string = ''

  messageAddress: string = ''

  messageTitle: string = ''

  netrikaGuid: string = ''

  numberOfMis: number = null

  ogrn: string = ''

  okpo: string = ''

  onlineRecordingInformation: string = ''

  phone1: string = null

  phone2: string = null

  servicesAvailableInClientWidget: boolean = false

  showOnlineRecordingInformation: boolean = true /* showOnlineRecordingInformation: 8693nakv3 */

  specialistsAvailableInClientWidget: boolean = false

  status: 'active' | 'not_active' = 'active'

  timeZone: string = null

  title: string = ''

  web: string = ''

  feedbackUrl: string = ''

  constructor (data?: IClinic | Record<string, unknown>) {
    super()
    this._fillProps(data)
    this.addressAttributes = new MAddressModel(
      data?.addressAttributes as IAddress || {}
    )
    this.saveTaxRate()
  }

  getAttributes (): Record<string, unknown> | {} {
    return {
      ...super.getAttributes(),
      addressAttributes: this.addressAttributes.getAttributes(),
    }
  }

  checkTaxRateChanged () {
    return this.taxRate !== this.taxRateOldValue
  }

  protected saveTaxRate () {
    this.taxRateOldValue = this.taxRate
  }
}
