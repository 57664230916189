import { drawNDS } from '@/forms/components/order/NDS/drawNDS'
import { updateCommentTooltip } from '@/forms/components/entry/updateCommentTooltip'
import { getNDSForEntry } from '@/forms/components/order/NDS/getNDSForEntry'

FormFactory.orderEntry = function () {
  const COMPLEX = 6
  const GOOD = 5

  const round = Utils.moneyRound
  const appSettings = gon.application.settings
  const lockedOrder = gon.specific.order_locked
  const banPaidOrder = gon.application.ban_paid_order_attributes

  $.NestedFieldSet.register('f-order-nested-entry', {
    name: 'entries',
    render () {
      this.setFieldsLock(
        !(appSettings.flexible_price && this.getKindString() !== 'complex' && !lockedOrder),
        'price'
      )

      this.setFieldsLock(
        this.get('discount_disabled', 'bool') || lockedOrder,
        'discount_value',
        'discount_reason_id'
      )

      this.setFieldsLock(
        banPaidOrder && lockedOrder,
        'referral_id',
        'user_id'
      )

      this.setFieldVisibility('discount_value', !this.isComplex())

      const kindString = this.getKindString()

      this.setPerformersVisibility(
        kindString !== 'good' && kindString !== 'complex'
      )

      this.updateStoreVisibility()

      if (kindString === 'complex') {
        this.cache('discount-sign-hidden', function () {
          this.html().find('.discount-sign').hide()

          return true
        })
      }

      this.updateDiscountTypeBtns(this.getDiscountType())

      this.updateContentIcon({
        visible: this.getKind() !== COMPLEX &&
                 this.getKind() !== GOOD,
        number: this
          .getDataArray('consumables')
          .filter(function (e) { return !e.isDeleted() })
          .length,
      })

      if (this.data('locked_from_billing_changes')) this.lockFromBillingChanges()

      this.formatMoneyField('price')
      this.formatMoneyField('final_sum')

      drawNDS(this)
    },

    extend: $.extend({}, FormFactory.orderEntryCommon(), {
      getMemberTemplate () {
        return this.html().find('._template_members')
      },

      getConsumableTemplate () {
        return this.html().find('._template_consumables')
      },

      appendMemberHtml (html) {
        const membersEnd = this.cache('membersEnd', function () {
          return this.html().nextAll('.f-entry-members-end').first()
        })

        $(html).insertBefore(membersEnd)
      },

      appendConsumableHtml (html) {
        const consumablesEnd = this.cache('consumablesEnd', function () {
          return this.html().nextAll('.f-entry-consumables-end').first()
        })
        $(html).insertBefore(consumablesEnd)
      },

      _initSelectors () {
        const memberSelector = this.getChildRow('.f-entry-members-selector-row')
        const consumableSelector = this.getChildRow('.f-entry-consumables-selector-row')

        this.data('memberSelector', memberSelector)
        this.data('consumableSelector', consumableSelector)

        memberSelector.data('complex', this)
        memberSelector.data('type', 'members')
        consumableSelector.data('entry', this)
        consumableSelector.data('type', 'consumables')
      },

      _updateConsumableIndex (parent, consumable, index) {
        consumable.setIndex(index)
        consumable.setParentIndex(parent.getIndex(), 'entries')
        consumable.setAttributeIndex(
          consumable.html().find('input.f-order-entry-store'),
          index,
          'consumables'
        )
        consumable.data('entry', parent)
        consumable.init()
      },

      _initChildren () {
        const entry = this

        const descendedMembers = this.getDescendedRows({
          start: 'f-entry-members-start',
          end: 'f-entry-members-end',
          filter: 'entry_member_row',
        })

        const descendedConsumables = this.getDescendedRows({
          start: 'f-entry-consumables-start',
          end: 'f-entry-consumables-end',
          filter: 'entry_consumable_row',
        })

        descendedMembers.forEach(function (member, index) {
          const memberConsumables = member.getDescendedRows({
            start: 'f-member-consumables-start',
            end: 'f-member-consumables-end',
            filter: 'entry_consumable_row',
          })

          member.setIndex(index)
          member.setParentIndex(entry.getIndex(), 'entries')
          member.data('complex', entry)
          memberConsumables.forEach((consumable, i) => entry._updateConsumableIndex(member, consumable, i))
          member.init()
        })

        descendedConsumables.forEach(function (consumable, index) {
          entry._updateConsumableIndex(entry, consumable, index)
        })

        entry.initDataCollection(descendedMembers, 'members')
        entry.initDataCollection(descendedConsumables, 'consumables')
      },

      init () {
        this._recalculatesCount = 0

        this._initSelectors()
        this._initChildren()
        this._initDiscountSelector()
        this.ensureMaxDiscount()

        if (this.isComplex()) {
          this.set('user_id', undefined)
          if (this.field('assistant_id')) this.set('assistant_id', undefined)
        }

        this.updateSchema()
        this.recalculate()
        this.redraw()

        updateCommentTooltip(this.html())
      },

      showDirectChildren () {
        this.getDataArray('members').forEach(function (member) {
          if (!member.isDeleted()) member.html().show()
        })

        this.getDataArray('consumables').forEach(function (consumable) {
          if (!consumable.isDeleted()) consumable.html().show()
        })

        if (this.getKind() !== COMPLEX) this.data('consumableSelector').show()
        if (this.getKind() === COMPLEX) this.data('memberSelector').show()
      },

      // update schema on add, remove or delete member
      updateSchema () {
        this._updateSchema('consumables', 'consumableSchema')
        this._updateSchema('members', 'memberSchema')
      },

      updateContentItems () {
        this._updateContentItems('members', 'memberSchema')
        this._updateContentItems('consumables', 'consumableSchema')
        this.recalculate()
      },

      recalculate () {
        this._recalculatesCount++

        if (this.isComplex()) {
          this._recalculateDynamic()
        } else {
          const attrs = this.recalculateMoneyAttrs(this.get())

          const newAttrs = this.formatAttrs(attrs)
          // price was already changed, no need to update it again
          delete newAttrs.price

          if (this.noCriticalChanges(newAttrs)) return

          this.set(newAttrs)
        }

        this.redraw()
      },

      gracefulDestroy () {
        if (this.get('discount_type') === 'currency' && this.get('discount_value') > 0) {
          PubSub.emit('page.form.order.calculateBonusDiscount.cancelApplyingBonusDiscount')
        }

        this.getDataArray('members').forEach(function (member) {
          member.gracefulDestroy()
        })

        this.getDataArray('consumables').forEach(function (consumable) {
          consumable.gracefulDestroy()
        })

        this.getContentHtml('entry').hide()
        this.destroy()
      },

      /**
       * Работа с комплексами
       * @private
       */
      _recalculateDynamic () {
        const referralId = this.get('referral_id')

        const attrs = {
          sum: 0,
          final_sum: 0,
          _ndsValue: 0,
        }

        this.getDataArray('members').forEach(function (member) {
          if (member.isDeleted()) return

          member.setRaw('referral_id', referralId)
          member.recalculate()
          attrs.sum += member.get('sum', 'float')
          attrs.final_sum += member.get('final_sum', 'float')
          attrs._ndsValue += (getNDSForEntry(member).ndsValue || 0)
        })

        attrs.sum = round(attrs.sum)
        attrs.final_sum = round(attrs.final_sum)
        attrs.price = round(attrs.sum / parseFloat(this.get('amount')))
        attrs.final_price = round(attrs.final_sum / parseFloat(this.get('amount')))
        attrs.discount_sum = round(attrs.sum - attrs.final_sum)
        this.set(this.formatAttrs(attrs))
      },
    }),
  })

  return {}
}
