import { TEntryRawLikeNestedFieldSet } from '@/specific/orders_helpers/NDS/orderShowNDS/declarations'
import { getNDSForEntry } from '@/forms/components/order/NDS/getNDSForEntry'

export const prepareMembers =
  (members: TEntryRawLikeNestedFieldSet[] = []) => {
    ;(members || []).forEach((member) => {
      member.get = (key) => member[key]
      const { schemeTitle } = getNDSForEntry(member)
      member._ndsTitle = schemeTitle
    })
  }
