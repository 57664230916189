import { TAX_RATES } from '@/vue_apps/Taxes/const/const'
import { NumberHelpers } from '@/helpers/NumberHelpers'

const ndsCalculate = (finalSum: string, taxRateValue: number) =>
  +finalSum * taxRateValue / (100 + taxRateValue)

export const NDS_CALCULATORS = {
  [TAX_RATES.NDS_NONE]: (finalSum: string) => 0,
  [TAX_RATES.NDS_0]: (finalSum: string) => 0,
  [TAX_RATES.NDS_5]: (finalSum: string) => ndsCalculate(finalSum, 5),
  [TAX_RATES.NDS_7]: (finalSum: string) => ndsCalculate(finalSum, 7),
  [TAX_RATES.NDS_10]: (finalSum: string) => ndsCalculate(finalSum, 10),
  [TAX_RATES.NDS_12]: (finalSum: string) => ndsCalculate(finalSum, 12),
  [TAX_RATES.NDS_18]: (finalSum: string) => ndsCalculate(finalSum, 18),
  [TAX_RATES.NDS_20]: (finalSum: string) => ndsCalculate(finalSum, 20),
  [TAX_RATES.NDS_25]: (finalSum: string) => ndsCalculate(finalSum, 25),
  [TAX_RATES.NDS_10_TO_110]: (finalSum: string) => ndsCalculate(finalSum, 10),
  [TAX_RATES.NDS_18_TO_118]: (finalSum: string) => ndsCalculate(finalSum, 18),
  [TAX_RATES.NDS_20_TO_120]: (finalSum: string) => ndsCalculate(finalSum, 20),
}

export const getNDSValue = (finalSum: string, taxRate: TAX_RATES) => {
  if (!NDS_CALCULATORS[taxRate]) {
    return 0
  }

  return NumberHelpers.toMoney(NDS_CALCULATORS[taxRate](finalSum))
}
