import { TEntryRawLikeNestedFieldSet } from '@/specific/orders_helpers/NDS/orderShowNDS/declarations'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import { TAX_RATES, TAX_RATES_TEXTS } from '@/vue_apps/Taxes/const/const'

export const drawNDSTotal =
  (topLevelEntries: TEntryRawLikeNestedFieldSet[]) => {
    const totalNDSSumContainer = document.querySelector('#order_nds_sum_value') as HTMLElement
    if (!totalNDSSumContainer) { return }

    const allNdsIsNDSNone = (topLevelEntries || []).every((e) => e.tax_scheme === TAX_RATES.NDS_NONE)
    if (allNdsIsNDSNone) {
      totalNDSSumContainer.innerText = TAX_RATES_TEXTS[TAX_RATES.NDS_NONE]

      return
    }

    totalNDSSumContainer.innerText = vueFilters.currency((topLevelEntries || []).reduce(
      (acc, entry) => acc + entry._ndsValue, 0)
    ).replace(' ', '')
  }
